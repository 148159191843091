// package imports
import { IonRow, IonCol, IonGrid, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";

// local imports
import "./dropdown.scss";
import OutsideAlerter from "@hoc/OutsideAlerter/OutsideAlerter";
import { getImagePath } from "@utils/helpers/media.helper";

// interfaces
interface optionProps {
  selectedLocation?: string | null;
  listNames?: string[];
  listNamesObjectArr?: { name: string; value: string }[];
}

interface InputProps {
  options: optionProps;
  classes?: string;
  optionPlaceholder?: string;
  styleProps?: Record<string, unknown>;
  parentElement?: string;
  handleChange?: (name: string, stateName: any, value: any, isDate?: boolean) => void;
  name?: string;
  onChange?: (selectedList: string) => void;
  isDisabled?: boolean;
  showAddressDropdown?: boolean;
  addressList?: Record<string, any>;
  addrValue?: string;
  apiValue?: string;
  dropdownHeight?: string;
  dropdownMaxHeight?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Dropdown = ({
  classes,
  styleProps,
  options = {},
  optionPlaceholder,
  parentElement,
  handleChange,
  name,
  onChange,
  isDisabled,
  showAddressDropdown,
  addressList,
  addrValue,
  dropdownHeight = "100%",
  dropdownMaxHeight = "100%"
}: InputProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<string>("");
  const [selectedOptionName, setSelectedOptionName] = useState<string>("");

  let disabled = !options?.listNames?.length;
  if (options.listNamesObjectArr && !options.listNamesObjectArr?.length) disabled = true;

  const handleSelectedList = (list: string, optionName?: string) => {
    if (list) {
      handleChange && handleChange(parentElement ? parentElement : "", name, list);
      options.listNamesObjectArr?.length && optionName && setSelectedOptionName(optionName);
      setSelectedList(list);
      onChange && onChange(list);
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const handleSelectAddressList = (list: Record<string, any>) => {
    if (list) {
      if (handleChange) {
        handleChange("contact", "postcoder_address", list);
      }
      setSelectedList(list?.summaryline);

      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (options.selectedLocation) {
      setSelectedList(options.selectedLocation);
    }
  }, [options.selectedLocation]);

  useEffect(() => {
    if (addressList && addressList?.length > 0) {
      setIsOpen(true);
    }
    if (addressList && addressList?.length == 0) {
      setSelectedList("");
      setIsOpen(false);
    }
  }, [addressList?.length]);

  const getTitle = () => {
    if (options.selectedLocation) return options.selectedLocation;
    if (selectedOptionName) return selectedOptionName;
    return selectedList;
  };

  return (
    <OutsideAlerter
      onOutsideClick={() => {
        setIsOpen(false);
      }}
    >
      <IonRow className={`dd-wrapper pl_1px ${classes}`} style={styleProps}>
        <IonCol
          className={`dd-header ${isDisabled ? "disableClass" : ""} ${
            isOpen && !disabled ? "post-coder-postcode-focus" : ""
          }`}
          onClick={() => (isDisabled ? "" : setIsOpen((prevState: boolean) => !prevState))}
        >
          <IonGrid className="disabled"></IonGrid>
          {!showAddressDropdown && (
            <IonGrid className="dd-header-title">
              {!getTitle() && optionPlaceholder ? (
                <IonText className="option-placeholder">{optionPlaceholder}</IonText>
              ) : (
                getTitle()
              )}
            </IonGrid>
          )}
          {showAddressDropdown && (
            <IonGrid className="dd-header-title">{addrValue || selectedList}</IonGrid>
          )}
          <IonRow className={`${isOpen ? "dd-header-icon-rotated" : "dd-header-icon"}`}>
            {" "}
            <img src={getImagePath(`svg/arrow-up.svg`)} alt="dropdown-arrow" />
          </IonRow>
        </IonCol>
        {!showAddressDropdown && (
          <IonCol className={`dd-list-wrapper ${isOpen ? "d-block" : ""}`}>
            <IonGrid
              className="dd-list"
              style={{ height: dropdownHeight, maxHeight: dropdownMaxHeight }}
            >
              {options?.listNamesObjectArr?.length
                ? options.listNamesObjectArr.map((item) => {
                    return (
                      <IonGrid
                        className="dd-list-item"
                        onClick={() => handleSelectedList(item.value, item.name)}
                        key={item.value}
                      >
                        {item.name}
                      </IonGrid>
                    );
                  })
                : options.listNames?.map((list) => (
                    <IonGrid
                      className="dd-list-item"
                      onClick={() => handleSelectedList(list)}
                      key={list}
                    >
                      {list}
                    </IonGrid>
                  ))}
            </IonGrid>
          </IonCol>
        )}
        {showAddressDropdown && (
          <IonCol className={`dd-list-wrapper ${isOpen ? "d-block" : ""}`}>
            <IonGrid className="dd-list" style={{ height: dropdownHeight }}>
              {addressList?.map((list: any) => (
                <IonGrid
                  className="dd-list-item"
                  onClick={() => handleSelectAddressList(list)}
                  key={list.summaryline}
                >
                  {list.summaryline}
                </IonGrid>
              ))}
            </IonGrid>
          </IonCol>
        )}
      </IonRow>
    </OutsideAlerter>
  );
};

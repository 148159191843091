import { PROPERTY_LISTING_TYPE } from "@constants/enum";
import { getFormattedPrice } from "@utils/helpers";

export type FilterKey = "location" | "furnishing" | "propertyType";

export interface CheckboxDataType {
  id: string;
  label: string;
  checked: boolean;
  sever_id: string;
}

export const locationInitState: Array<CheckboxDataType> = [
  {
    id: "pfp-l-1",
    label: "All Locations",
    checked: false,
    sever_id: ""
  },
  {
    id: "pfp-l-2",
    label: "North London",
    checked: false,
    sever_id: "N"
  },
  {
    id: "pfp-l-3",
    label: "East London",
    checked: false,
    sever_id: "E"
  },
  {
    id: "pfp-l-4",
    label: "South East London",
    checked: false,
    sever_id: "SE"
  },
  {
    id: "pfp-l-5",
    label: "South West London",
    checked: false,
    sever_id: "SW"
  },
  {
    id: "pfp-l-6",
    label: "West London",
    checked: false,
    sever_id: "W"
  },
  {
    id: "pfp-l-7",
    label: "North West London",
    checked: false,
    sever_id: "NW"
  },
  {
    id: "pfp-l-8",
    label: "Outer London",
    checked: false,
    sever_id: "O"
  }
];

export const furnishingInitState: Array<CheckboxDataType> = [
  {
    id: "pfp-f-1",
    label: "Furnished",
    checked: false,
    sever_id: "Furnished"
  },
  {
    id: "pfp-f-2",
    label: "Unfurnished",
    checked: false,
    sever_id: "Unfurnished"
  },
  {
    id: "pfp-f-3",
    label: "Flexible",
    checked: false,
    sever_id: "Flexible"
  }
];

export const propertyTypeInitState: Array<CheckboxDataType> = [
  {
    id: "pfp-pt-1",
    label: "Single level",
    checked: false,
    sever_id: "single_level"
  },
  {
    id: "pfp-pt-2",
    label: "Duplex",
    checked: false,
    sever_id: "duplex"
  },
  {
    id: "pfp-pt-3",
    label: "Triplex",
    checked: false,
    sever_id: "triplex"
  },
  {
    id: "pfp-pt-4",
    label: "Terraced",
    checked: false,
    sever_id: "terraced_house"
  },
  {
    id: "pfp-pt-5",
    label: "End of terrace",
    checked: false,
    sever_id: "end_of_terrace"
  },
  {
    id: "pfp-pt-6",
    label: "Detached",
    checked: false,
    sever_id: "detached"
  },
  {
    id: "pfp-pt-7",
    label: "Semi-detached",
    checked: false,
    sever_id: "semi_detached"
  },
  {
    id: "pfp-pt-8",
    label: "Bungalow",
    checked: false,
    sever_id: "bungalow"
  },
  {
    id: "pfp-pt-9",
    label: "Cottage",
    checked: false,
    sever_id: "cottage"
  }
];

export const PROPERTY_FILTER_KEYS = {
  location: "location",
  furnishing: "furnishing",
  propertyType: "property_type",
  move_in_date: "move_in_date",
  no_max: "max_price_range",
  no_min: "min_price_range",
  pets: "pets",
  parking: "parking",
  bathroom: "bathrooms",
  bedroom: "bedrooms"
};

export interface FilterOptionsServer {
  location?: string;
  furnishing?: string;
  property_type?: string;
  move_in_date?: string;
  max_price_range?: string;
  min_price_range: string;
  pets: string;
  parking: string;
  bathrooms: string;
  bedrooms: string;
}

const PRICE_RANGE = (() => {
  const result = [];

  for (let i = 100; i <= 2000; i += 100)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.RENT), value: String(i) });
  for (let i = 2200; i <= 4000; i += 200)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.RENT), value: String(i) });
  for (let i = 4500; i <= 10000; i += 500)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.RENT), value: String(i) });
  for (let i = 12000; i <= 20000; i += 2000)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.RENT), value: String(i) });
  for (let i = 22500; i <= 50000; i += 2500)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.RENT), value: String(i) });

  return result;
})();

const SALE_PRICE_RANGE = (() => {
  const result = [];

  for (let i = 50000; i <= 300000; i += 10000)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.SALE), value: String(i) });
  for (let i = 325000; i <= 500000; i += 25000)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.SALE), value: String(i) });
  for (let i = 550000; i <= 1000000; i += 50000)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.SALE), value: String(i) });
  for (let i = 1250000; i <= 3000000; i += 250000)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.SALE), value: String(i) });
  for (let i = 3250000; i <= 5000000; i += 1000000)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.SALE), value: String(i) });
  for (let i = 7500000; i <= 10000000; i += 2500000)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.SALE), value: String(i) });
  for (let i = 15000000; i <= 20000000; i += 5000000)
    result.push({ name: getFormattedPrice(i, PROPERTY_LISTING_TYPE.SALE), value: String(i) });

  return result;
})();

export const PROPERTY_FILTER_MIN_PRICE_RANGE = [
  {
    name: "No min",
    value: "No min"
  },
  ...PRICE_RANGE
];

export const PROPERTY_FILTER_MAX_PRICE_RANGE = [
  {
    name: "No max",
    value: "No max"
  },
  ...PRICE_RANGE
];
export const SALE_PROPERTY_FILTER_MIN_PRICE_RANGE = [
  {
    name: "No min",
    value: "No min"
  },
  ...SALE_PRICE_RANGE
];

export const SALE_PROPERTY_FILTER_MAX_PRICE_RANGE = [
  {
    name: "No max",
    value: "No max"
  },
  ...SALE_PRICE_RANGE
];

export const MAX_PRICE_FILTER_VALUE = 20000001;
